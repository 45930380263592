import React, { useState } from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import { CloseIcon } from '../../Helper/Icons'
import { Button, Modal } from '../../Component/Commons'
import moment from 'moment'
import { useTranslation } from "react-i18next";

const ViewEvent = ({ handleClose, modalOpen, closeFlag }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    isEdit: ""
  });

  const response = modalOpen?.data?.totalData;

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {t("DetailsOfEvent")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Box width={{ sm: '400px' }} sx={{ border: '1px solid #333', padding: '30px', color: '#fff' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("Events")}</Grid>
                <Grid item xs={7} sx={{ overflowWrap: 'anywhere' }}> {response.camera_event ? response.camera_event : "-"}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("Location")}</Grid>
                <Grid item xs={7}>{response.location_name ? response.location_name : '-'}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("Area")}</Grid>
                <Grid item xs={7}>{response.area_name ? response.area_name : '-'}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("Time&Date")}</Grid>
                <Grid item xs={7}>{moment(response.created_at).format("DD-MM-YYYY")}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("Camera")}</Grid>
                <Grid item xs={7}>{response.camera_name ? response.camera_name : '-'}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        {/* <Button buttonName={t("Edit")} size='small' onClick={() =>
          setIsModalOpen({
            open: true,
            currentComponent: "addEditEvent",
            isEdit: true,
            data: {
              totalData: response,
            }
          })
        } />
        <Button buttonName={t("Delete")} size='small' color="secondary" style={{ color: '#fff', borderColor: '#2c2c2c' }} variant='outlined'
          onClick={() =>
            setIsModalOpen({
              open: true,
              currentComponent: "delete",
              isEdit: false,
              data: {
                head: `${t("DeleteEvent")}`,
                message: `${t("AreYouSureWanttoDeletethisEvent?")}`,
                id: response.id,
                name: "EVENT",
              },
            })
          }
        /> */}
      </DialogActions>
      <Modal
        modalOpen={isModalOpen}
        closeFlag={closeFlag}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        } />
    </>
  )
}

export default ViewEvent