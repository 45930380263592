import { get } from "../web.request";

export const DashboardHandler = () => {
    return get(`${process.env.REACT_APP_API_URL}/alert_event_camera_location_count`);
};

export const reportChartHandler = () => {
    return get(`${process.env.REACT_APP_API_URL}/undetected_datewise_event_count`)
};

export const analyticsDataHandler = () => {
    return get(`${process.env.REACT_APP_API_URL}/undetected_event_percentage`);
};

export const cameraDataHandler = (search, page, location, area) => {
    return get(`${process.env.REACT_APP_API_URL}/create_camera?search=&page=${page}&search=${search}&location_id=${location}&area_id=${area}`);
};

export const eventDataHandler = (search, page, location, area, camera) => {
    return get(`${process.env.REACT_APP_API_URL}/create_camera_event?search=${search}&page=${page}&location_id=${location}&area_id=${area}&camera_id=${camera}`);
};
export const eventDataHandlerSelectBox = (dashboard) => {
    return get(`${process.env.REACT_APP_API_URL}/create_camera_event?&dashboard=${dashboard}`);
};

export const topFourDataHandler = () => {
    return get(`${process.env.REACT_APP_API_URL}/top_four_alert_data`);
};