import { DELETE, get, post, put } from "../web.request";

export const createAreaHandler = (payload) => {
    return post(`${process.env.REACT_APP_API_URL}/create_area`, payload);
};

export const updateAreaHandler = (id, payload) => {
    return put(`${process.env.REACT_APP_API_URL}/update_area/${id}`, payload);
};


export const deleteAreaHandler = (id) => {
    return DELETE(`${process.env.REACT_APP_API_URL}/delete_area/${id}`)
};


export const getAreaHandler = (locationId, orgId, search, page) => {
    return get(`${process.env.REACT_APP_API_URL}/create_area?location_id=${locationId}&organization_id=${orgId}&search=${search}&page=${page}`);
};

// Get orgList By orgId and locationId

export const getAreaById = (orgId, locId, search) => {
    return get(`${process.env.REACT_APP_API_URL}/area_list?organization_id=${orgId}&location_id=${locId}&search=${search}`)
}